import {abortNavigation, createError, defineNuxtRouteMiddleware, useNuxtApp} from '#imports';
import type {FetchError} from '@api';
import {usePasswordRenewalStore} from '~/store/password-renewal.store';

export default defineNuxtRouteMiddleware(async (to) => {
    const {$pinia} = useNuxtApp();
    const {verifyToken} = usePasswordRenewalStore($pinia);

    const token = to.params.token as string;

    try {
        await verifyToken(token);
    } catch (e) {
        const error = e as FetchError;
        if (error.statusCode === 401 || error.statusCode === 403) {
            return abortNavigation(createError({statusCode: 404, statusMessage: 'Password renewal token invalid'}));
        }
        return abortNavigation(createError({statusCode: 500, statusMessage: 'Error while verifying the password renewal token'}));
    }
});
